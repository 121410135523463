html {
    height: 100%;
    width: 100%;
    
}

body {
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    margin-left:auto;
    margin-right:auto;
    padding: 0;
    font-family: serif;
    width: 100%;
    line-height: 0.5;
    background-image: linear-gradient(
        0deg,
        rgb(173, 159, 202) 0.5%,
        rgb(246, 246, 246) 95%
        );

        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        
        FOR THE FUTURE: THIS CENTERS 
        EVERYTHING IN A COLUMN!*/ 

}

.App {
    text-align: center;
    justify-content: center;
    background-color: rgb(246, 246, 246); /* rgb(47, 22, 8) */
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    line-height: 1.5;
}

h1 {
    justify-content: center;
    text-align: middle;
    /* padding-left: 30px; */
    font-family: 'SilversPersonal';
    font-style: normal;
    font-weight: normal;
    color: #242324;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 40px;
    /* transition: transform .1s; Animation */
}

.smaller {
    font-size: 23px;

}

h2 {
    color: #240505;
    font-family: "futura";
    margin: 0 auto;
}

.homepage {
    background-color: transparent;
    margin: 0 auto;
    padding: 0; 
    position: relative;
    top: 30px;  
    width: 59vw;
    line-height: 1.5;
    max-width: 800px;

}

h3 {
    text-align: center;
    font-family: 'futura';
    font-weight: bold;
    color: #242324;
    margin: 0 auto;
    padding: 0;
    font-size: 25px;
    position: relative;
    display: block;
    width: 100%
    
}


.smalltext {
    background-color: transparent;
    position: relative;
    width: 350px;
    height: 100px;
    padding-bottom: 40px;
    bottom: 310px;
}

.gradient {
    top: 10px;
    width: 100%;
    height: 281px;
    display: block;
    margin: 0 auto;
    position: relative;
}

p {
    font-family: "futura";
    color: grey;
    font-size: small;
    text-align: left; 
    vertical-align: middle;
    white-space: pre-line;
    position: inherit;
    bottom: 5px;
}

.spotify-trademark {
    font-family: "futura";
    color: rgb(98, 98, 98);
    font-size: small;
    text-align: left; 
    vertical-align: middle;
    white-space: pre-line;
    position: inherit;
    bottom: 250px;
}

.home-small {
    font-family: "futura";
    color: #242324;
    font-size: small;
    text-align: center; 
    white-space: pre-line;
    display: block;
    position: relative;

}

.home-small_3 {
    font-family: "futura";
    color: #242324;
    font-size: small;
    text-align: center; 
    white-space: pre-line;
    display: block;
    position: relative;

}

.home-small_2 {
    font-family: "futura";
    color: #242324;
    font-size: 11px;
    text-align: center; 
    white-space: pre-line;
    display: block;
    position: relative;
    bottom: 10px;
    font-style: italic;
    line-height: 0.5;

}

.audio {
    position: relative;
    margin: 0 auto;
    bottom: 235px;
    zoom: 1.2;
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    display: flex;
    padding: 10px 30px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    
}

audio {
    filter: sepia(50%) saturate(100%) grayscale(3) contrast(100%) invert(0%);
    position: relative;
    text-align: center;
    vertical-align: middle;
    bottom: 8px;
}

a {
    position: relative;
    text-align: center;
    border: none;
    background-color: #242324; 
    color: #ffffff;
    padding: 8px 12px;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 8px;
    font-family: 'futura';
    font-weight: bold;
    transition-duration: 0.1s;
}

a:hover {
    background-color: #BEB0DC; 
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
}

.artistnamelink {
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: large;
    font-family: 'futura';
    font-weight: bold;
    transition-duration: 0.1s;
    position: relative;
    border-radius: 8px;
    padding: 0px 0px;
}

.artistnamelink:hover {
    background-color: transparent;
    color: #BEB0DC ;
    cursor: pointer;
}

.artistname {
    color: white;
    font-family: 'futura';
    font-weight: bold;
    font-size: large;
    font-style: normal;
    position: relative;
    bottom: 512px;
    display: inline-block;

}

.popularity {
    color: white;
    font-family: 'futura';
    font-style: italic;
    font-size: 8px;
}

button {
    background-color: transparent; 
    border: none;
    color: #242324;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 8px;
    font-family: 'futura';
    font-weight: bold;
    transition-duration: 0.1s;
    margin: 10px;


}

button:hover {
    background-color: #242324; 
    color: white;
    cursor: pointer;
}


input {
    justify-content: center;
    text-align: left;
    font-family: 'futura';
    border-radius: 8px;
    padding: 4px 12px;
    border: none;
}

form {
align-items: center;
padding: 0%;
height: 100%;
width: 100%;
}

.headtext {
    position: relative;
    zoom: 0.8;
    line-height: 0.5;
    
}

img {
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    display: block;
    width: 400px; 
    height: auto;

}

.artistname {
    color: white;
    font-family: 'futura';
    font-weight: bold;
    font-size: large;
    font-style: normal;
    position: relative;
    bottom: 512px;
    display: inline-block;

}

.scorebox {
    background-color: transparent;
    position: relative;
    min-width: 135px;
    min-height: 134px;
    max-width: 135px;
    max-height: 134px;
    bottom: 448px;
    left: 20px;
    margin: 0;
    padding: 0;

}


.score {
    color: black;
    font-family: "futura";
    font-weight: bolder;
    font-style: normal;
    font-size: 20pt;
    position: absolute;
    margin:  auto;
    text-align: center;
    vertical-align: middle;
    line-height: 128px;  
    /* used to be 128px*/
    left: 10px;
    margin: 0;
    padding: 0;
    height: 50px;
}

img.artistcover {
    position: relative;
    bottom: 638px;
    min-width: 167px;
    min-height: 167px;
    max-width: 167px;
    max-height: 167px;
    object-fit: cover;
    margin: 0 auto;
    display: inline-block;
   
}

.save {
    position: relative;
    margin: 0 auto;
    display: inline-block;
}

.headimage {
    height: 679.5px;
    display: inline-block;
}

#myartist.headtext {
    max-height: 679.5px;
    width: 400px;
    display: inline-block;
    line-height: 1.4;
    white-space: nowrap;
    top: 20px;
}

.App-header {
   margin: 0 auto;
   text-align: center;
   justify-content: 0;
}

.underground {
    color: black;
    font-family: "futura";
    font-weight: bolder;
    font-style: normal;
    font-size: 7.3pt;
    position: absolute;
    margin:  auto;
    line-height: 115px;  
    /* used to be 128px*/
    left: 9px;
    top: 24px;
    margin: 0;
    padding: 0;
}
